import React,{useEffect} from 'react';
import { Header, Footer} from '../components';


 export const Layout = ({ children }) => {
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: Add smooth scrolling animation
      });
    }
  }, []);

  return (
    <> 
    <Header/>      
     {children}
    <Footer/>
    </>
    
  )
}