import React from 'react';
import { EventDescription } from '../styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { MARKS, BLOCKS } from "@contentful/rich-text-types"

export const EventDescriptionRender = ({ content }) => {
  //Render Variable
  const eventDescription = content;
  //Conditional render. If there is no content then it returns null.
  if(!content) {
    return null;
  }
  //Options
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNORDERED_LIST]: text => <ul>{text}</ul>,
      [MARKS.LIST_ITEM]: text => <li>{text}</li>
    },
    renderNode: {
     
      
    },
  }
//Return
  return(
    <EventDescription>
       {eventDescription && renderRichText(eventDescription,options)}
    </EventDescription>
  )
}