import React from 'react';
import { InternalLink, EventCard, FeatureImage, TextWrapper, EventCardHeadline, EventDate, EventLocation } from '../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const EventCardComponent = ({ eventData }) => {
  const Feature = getImage(eventData.featureImage);
  const eventHeadline = eventData.eventHeadline;
  const eventDateFrom = eventData.dateFrom || '' ;
  const eventDateTo = eventData.dateTo || '';
  const eventLocation = eventData.eventLocation;
  
   // Split eventDateFrom into date and time
   const dateFromParts = eventDateFrom.split(' ');
   const monthDayFrom = dateFromParts.slice(0, 2).join(' ');
   const timeFrom = dateFromParts.slice(2).join(' ');
 
   // Split eventDateTo into date and time
   const dateToParts = eventDateTo.split(' ');
   const monthDayTo = dateToParts.slice(0, 2).join(' ');
   const timeTo = dateToParts.slice(2).join(' ');
 
   // Check if there is no dateTo or if it's the same as dateFrom
   const hasNoDateTo = !eventDateTo || eventDateTo === eventDateFrom;

  return(
    <EventCard>
      <InternalLink to={eventData.slug}>
      <div className="flex">
        <FeatureImage>
          <GatsbyImage
            image={Feature}
            alt=''
            loading='eager'
            className='full-width'
          />
        </FeatureImage>
        <EventCardHeadline>{eventHeadline}</EventCardHeadline>
        <EventDate className='card-date'>
          {monthDayFrom} {timeFrom}
          {hasNoDateTo ? '' : ` - ${timeTo}`}
       </EventDate>
      </div>     
      </InternalLink>
    </EventCard>
  )
}