import React from 'react';

import {InfoFlex} from '../styles';
import {FooterTitle, FooterOutsideLink} from "../styles"

//Styled Elements

 export const FooterInfo = (props) => {
  return(
    <InfoFlex>
      <FooterTitle>{props.Title}</FooterTitle>
      <FooterOutsideLink href={props.LocLink} target={'_blank'}>{props.Location}</FooterOutsideLink>
      <FooterOutsideLink href={props.EmailLink} target={'_blank'}>{props.Email}</FooterOutsideLink>
      <FooterOutsideLink href={props.PastorEmailLink} target={'_blank'}>{props.PastorEmail}</FooterOutsideLink>  
      <FooterOutsideLink href={props.ChurchPhoneLink} target={'_blank'}>{props.ChurchPhone}</FooterOutsideLink>
    </InfoFlex>
  )
}