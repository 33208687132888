import styled from "styled-components";
import { FacebookSquare,Instagram,Youtube } from "styled-icons/boxicons-logos";
import { ArrowDown } from "styled-icons/evaicons-solid";

export const FacebookIcon = styled(FacebookSquare)`
  
`
export const InstagramIcon =styled(Instagram)`

`
export const YoutubeIcon =styled(Youtube)`
  
`
export const Arrow = styled(ArrowDown)`

`