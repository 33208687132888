import React from 'react';
import { BannerSubHead } from '../styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { MARKS } from "@contentful/rich-text-types"

export const BannerTextRender = ({ content }) => {
  //Render Variable
  const subHead = content;
  //Conditional render. If there is no content then it returns null.
  if(!content) {
    return null;
  }
  //Options
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
    }
  }
//Return
  return(
    <BannerSubHead>
       {subHead && renderRichText(subHead,options)}
    </BannerSubHead>
  )
}