import styled, {css} from "styled-components";

//Event Page Card.
export const EventCard = styled.div`
  flex: 1 1 28em;
  justify-self: start;
  align-self: start;
  div{
    
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    gap: .25em;  
  }

  ${props => props.HomepageEventFeature && css`
    display: flex;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    transition: ${(props) => (props.isActive ? 'all .8s ease-in-out .1s ':'opacity .8s ease-in-out')};
    position: ${(props) => (props.isActive ? 'relative':'absolute')};
    flex-flow: column nowrap;
    gap: 1.5em 2em;
    justify-content:center;
    align-items:center;
    justify-self: center;
    align-self: center;
    width: 100%;
    
    @media ${({theme}) => theme.breakpoints.tablet}{
      flex: 1 1 18em;
      width: 100%;
    }
    @media ${({theme}) => theme.breakpoints.mobile}{
      flex: 1 1 14em;
      width: 100%;
    }
  `}
`
//Feature Image that sits inside the Event Card
export const FeatureImage = styled.div`
  position: relative;
  aspect-ratio: 2;
  flex: 1 1 100%;
  width: 100%;
  border-radius: .5em;

  ${props => props.homepageFeatureImage && css` 
    box-shadow: ${(props) => (props.isActive ? '0em .5em .5em rgba(0,0,0,.33), 0em 1em 1em rgba(0,0,0,.22)' : '')};
    transition: ${(props) => (props.isActive ? 'box-shadow .5s ease-in-out .3s' : '')};
    
    @media ${({theme}) => theme.breakpoints.tablet}{
      flex: 1 1 100%;
      width: 100%;
    }
    @media ${({theme}) => theme.breakpoints.mobile}{
     
    }
  `}

  .full-width{
    position:absolute;
    border-radius: .5em;
    overflow: hidden;
    width:100%;
    height: 100%;
  }
  
  @media ${({theme}) => theme.breakpoints.tablet}{
   
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    flex: 1 1 200px;
  }
`
//Main Content such description and Headline
export const EventMain = styled.main`
  grid-column: 2/3;
  grid-row: 1/2;
  display: flex;
  flex-flow: column nowrap;
  gap: .5em;
  @media ${({theme}) => theme.breakpoints.tablet}{
    grid-row: 2/3;
    grid-column: 2/4;
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    grid-row: 2/3;
    grid-column: 2/4;
  }
`
export const EventDescription = styled.div`
  line-height: 1.5;
  p{
    padding:.25em 0;
  }
  hr{
    margin: .5em 0;
    background:black;
    color: black;
    height: 4px;
    border-radius: 1em;
  }
  ul{
    display: flex; 
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items:flex-start;
    
    li{
      list-style: disc outside;
    }
  }
`
//Event Information 
export const EventInfo = styled.aside`
  grid-column: 3/4;
  grid-row: 1/2;
  align-self:start;
  background: ;
  padding: 1em;
  border-radius: .5em;
  border: 2px solid white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 1em;

  p{
    font-weight: ${({theme}) => theme.fontWeight.medium};
  }
  @media ${({theme}) => theme.breakpoints.tablet}{
    grid-row: 1/2;
    grid-column: 2/4;
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    grid-row: 1/2;
    grid-column: 2/4;
  }
`
//Event Components used on template page, event cards, and homepage
export const EventCardHeadline = styled.h4`
  font-size: 24px;
  font-weight: ${({theme}) => theme.fontWeight.light};

  @media ${({theme}) => theme.breakpoints.mobile}{
    font-size: 20px;
  }
  
`
export const EventHeadline = styled.h2`
    font-family: ${({theme}) => theme.fonts.reading};
    font-weight: ${({theme}) => theme.fontWeight.medium};
    font-size: 40px;
    line-height: 1.1;
    @media ${({theme}) => theme.breakpoints.tablet}{
      font-size: 32px;
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
      font-size: 24px;
  }
`
export const EventDate = styled.div`
  display:flex;
  flex-flow: column nowrap;
  justify-content:flex-start;
  align-items:center;

  &.card-date{
    flex-flow: row nowrap;
  }
`
export const EventLocation = styled.div`
  display:flex;
  flex-flow: column nowrap;
  justify-content:flex-start;
  align-items:center;
`
