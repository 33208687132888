import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import { Section, Container, } from '../styles';
import { EventCardComponent } from './event-card';
export const EventsRender = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulEvent(sort: {dateFrom: ASC}) {
      nodes {
        contentful_id
        internalName
        name
        slug
        dateFrom(formatString: "MMM Do hh:mm A")
        dateTo(formatString: "MMM Do hh:mm A")
        eventHeadline
        eventLocation
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            outputPixelDensities: 1.5
            resizingBehavior: SCALE
            quality: 60
            formats: [WEBP, AUTO]
            cropFocus: CENTER
          )
        }
      }
    }
  }
  `)
const EventData = data.allContentfulEvent.nodes;


return(
  <Section Events>
    <Container EventCards>
      {EventData.map((component) => {
        return(
          <EventCardComponent
            eventData={component}
            key={component.contentful_id}
          />
        )
      })}
    </Container>
  </Section>
  )
}

