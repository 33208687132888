import styled from 'styled-components';

export const BannerContainer = styled.div` 
  display: grid;
  grid-template-rows: 70vh;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 0 1rem;
  position:relative;
  transform-style:preserve-3d;
  z-index:-1; 
`
export const BannerImage = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  position:relative;
  
  transform:translateZ(-10px) scale(2.1);
  height: 100%;
  background: inherit;

  .full-width{
    position:absolute;
    width:100%;
    height: 100%;
  }
`

export const BannerTextWrapper = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  justify-self:center;
  align-self:center;
  display: flex;
  flex-flow: column;
  justify-content:center;
  align-items:center;
  position:relative;
  padding: 1em;
  text-align:center;
`
export const BannerHeadline = styled.h1`
  font-family: ${({theme}) => theme.fonts.main};
  font-size: 96px;
  font-weight: ${({theme}) => theme.fontWeight.light};
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.white};
  text-shadow: 1px 1px 5px black;
  
  @media ${({theme}) => theme.breakpoints.tablet}{
    font-size: 64px;
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    font-size: 48px;
  }
`
export const BannerSubHead = styled.div`
  p{
    color: ${({theme}) => theme.colors.white};
    font-weight: ${({theme}) => theme.fontWeight.medium};
    font-size: 24px;
  }
  strong{

  }
  em{

  }
` 