import styled,{ css } from "styled-components";

export const FooterContainer = styled.footer`
  display:grid;
  grid-template-columns: minmax(2rem,1fr) repeat(12, minmax(auto,9rem)) minmax(2rem,1fr);
  grid-auto-rows:auto;
  gap-column:1rem;
  background:${({theme}) => theme.colors.body};
  padding-top:5rem;

  @media ${props => props.theme.breakpoints.tablet}{
    grid-template-columns: minmax(2.5rem,1fr) repeat(8, minmax(auto,9rem)) minmax(2.5rem,1fr);
  }
  @media ${props => props.theme.breakpoints.mobile}{
     grid-template-columns: minmax(1rem,1fr) repeat(4, minmax(auto,9rem)) minmax(1rem,1fr);
  }
`
export const BottomWrapper = styled.div`
  display:flex;
  flex-flow: row wrap;
  justify-content:space-between;
  align-items:center;
  grid-column: 2 / 14;
  grid-row: 9 / 10;

  @media ${props => props.theme.breakpoints.tablet}{
    justify-content:center;
    align-items:center;
    grid-column: 3 / 9;
  }
  @media ${props => props.theme.breakpoints.mobile}{
    grid-column: 2 / 6;
  }
`
export const FooterHr = styled.hr`
width:100%;
grid-column: 2 / 14;
grid-row: 8 / 9;
margin:1rem 0 1rem 0;
border: 1px solid ${props => props.theme.colors.header};
border-radius:2rem;

  @media ${props => props.theme.breakpoints.tablet}{
    grid-column: 2 / 10;
  }
  @media ${props => props.theme.breakpoints.mobile}{
    grid-column: 2 / 6;
  }
`
export const FormSpan = styled.span`
  width:100%;

  ${props => props.LabelTitle && css`
     
  `}
  ${props => props.EmailInput && css`
  
  `}
  ${props => props.EmailSubmit && css`
    display: flex;
    display: -webkit-flex;
    justify-content: end;
    -webkit-justify-content: flex-end;
    flex: 1;
    -webkit-flex: 0 1 100%;
  `}
  @media ${props => props.theme.breakpoints.mobile}{
    ${props => props.LabelTitle && css`
     text-align:center;
  `}
    ${props => props.EmailSubmit && css`
      justify-content: center;
      -webkit-justify-content: center;
  `}
  }
`
export const InfoFlex = styled.div`
  display:flex;
  flex-flow:column nowrap;
  justify-content:flex-start;
  align-items:flex-start;
  gap:1rem;
  grid-column: 2 / 7;
  grid-row: 3 / 4;

  @media ${props => props.theme.breakpoints.tablet}{
    grid-column: 2 / 10;
    justify-content:center;
    align-items:center;
  }
  @media ${props => props.theme.breakpoints.mobile}{
    grid-column: 2 / 6;
  }
`
export const LogoWrapper = styled.div`
  grid-column: 2 / 5;
  grid-row: 2 / 3;
  margin-bottom:1rem;
  filter:drop-shadow(3px 3px 5px ${props => props.theme.colors.header});

  @media ${props => props.theme.breakpoints.tablet}{
    grid-column:  2 / 10;
    justify-self:center;
    align-self:center;
  }
  @media ${props => props.theme.breakpoints.mobile}{
    grid-column:  2 / 6;
  }
`
export const FooterSVG = styled.div`
  grid-column: 1 / 15;
  grid-row: 1 / 2;
  justify-self:center;
  align-self:end;
  width:100%;
  position:relative;
  font-size:0;

  svg{
    position:relative;
  }

  @media ${props => props.theme.breakpoints.tablet}{
    grid-column: 1 / 11;
  }
  @media ${props => props.theme.breakpoints.mobile}{
    grid-column: 1 / 7;
  }
`
export const FooterSocials = styled.div`
  display:flex;
  flex-flow:row nowrap;
  justify-content:end;
  align-items:end;
  grid-row: 7 / 8;
  grid-column: 12 / 14;
  justify-self:end;
  align-self:end;
  gap: 0rem 2.5rem;
  margin: 2rem 0 1rem 0;
  
  svg{ 
    height:50px;
    color:${({theme}) => theme.colors.header};
  }

  @media ${({theme}) => theme.breakpoints.tablet}{
    grid-column: 4 / 8;
    justify-self:center;
  }
  @media ${({theme}) => theme.breakpoints.mobile}{
    grid-column: 2 / 6;
    gap: 0rem 2.5rem;
  }

`
export const FooterTitle = styled.h3`
  font-family:${props => props.theme.fonts.reading};
  font-weight:${props => props.theme.fontWeight.medium};
  font-size:clamp(1.1rem,3vw,1.5rem);
  text-transform: uppercase;
  color:${props => props.theme.colors.header};

  @media ${props => props.theme.breakpoints.mobile}{
    text-align:center;
  }
`
export const FooterOutsideLink = styled.a`
  font-family:${props => props.theme.fonts.reading};
  font-weight:${props => props.theme.fontWeight.medium};
  font-size:1rem;
  text-decoration:none;
  color:${props => props.theme.colors.darkText};
`
export const FooterText = styled.p`
  font-family:${props => props.theme.fonts.reading};
  color:${props => props.theme.colors.darkText};
  text-align:center;
  padding:.2rem 0;
  
`
