import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PageHeader, HeaderLogoWrapper, InternalLink } from '../styles';
import { Mobile, Desktop } from './'; 

export const Header = () => {
  return(
   <PageHeader>
      <HeaderLogoWrapper>
      <InternalLink to="/">
        <StaticImage
          src="../images/Logo-Text.png"
          layout="constrained"
          alt=""
          quality={100}
          width={150}
        />
      </InternalLink>
      </HeaderLogoWrapper>
      <Desktop/>
      <Mobile/>
   </PageHeader>
  )
}