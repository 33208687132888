import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Main } from './src/themes/main';
import { GlobalStyles } from './src/styles/Global.styled';
import { Layout } from './src/layout/layout';
import "./src/fonts/fonts.css";
/**
 * @type {import('gatsby').GatsbySSR['onRenderBody']}
 */


//Wraps root element to provide the theme.
export const wrapRootElement = ({ element }) => {
  return(
    <ThemeProvider theme={ Main }>
      {element}
    </ThemeProvider>
  )
} 

//Wraps Every Page With A Layout and provides the global styles
//The layouts may change based on a switch statement that uses logic to figure out which page you are currently on.
export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
    <GlobalStyles/>
      {element}
    </Layout>
  )
}