import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { 
  Button, 
  OutsideLink, 
  TextWrapper, 
  SvgWrapper
} from "../styles";
import { HeroSection, HeroImage, PageTitle, AltPageTitleText } from "../styles/HeroSection";
import HeroWave from "../svg/assets/Alt-Hero-Curve.svg"



export const Hero = () => {
  return(
    <HeroSection className="homepage">
      <HeroImage className="homepage_hero">
        <StaticImage
          src="../images/Homepage/new_hero.webp"
          layout="fullWidth"
          alt="Front of Brielle Church"
          quality={60}
          formats={['auto','webp','jpg']}
          className="heroBg"
          loading="eager"
        />
      </HeroImage>
      <TextWrapper
        R="1/4"
        C="3/13"
        TC="2/10"
        MC="2/6"
        JS="center"
        AS="center"
        CenteredWrapper
        className="hero_wrapper"
      >
      <PageTitle>
        Welcome to The Church In Brielle
      </PageTitle>
        <AltPageTitleText WhiteColor>
          A center of spiritual wellness for knowing, loving, and serving Jesus Christ!
        </AltPageTitleText>
        <Button WhiteButton><OutsideLink href="https://m.facebook.com/Thechurchinbrielle/?mibextid=LQQJ4d">Facebook Live</OutsideLink></Button>
      </TextWrapper>
    </HeroSection>
  )
}
