import React,{useState} from 'react';
import { Button, Container, EventCard, EventCardHeadline, InternalLink, LargeHeadline, Section, Pagination, Circle, FeatureImage } from '../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

export const EventsFeature = () => {
const data = useStaticQuery(graphql`
  query {
    allContentfulEvent(limit: 3, filter: {eventRelation: {eq: "General"}} sort: {dateFrom: ASC}) {
      nodes {
        contentful_id
        internalName
        name
        slug
        eventHeadline
        featureImage {
          gatsbyImageData(
            formats: WEBP
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 90
            resizingBehavior: SCALE
            cropFocus: CENTER
          )
        }
      }
    }
  }
  `)
const eventData = data.allContentfulEvent.nodes;
const [activeEventIndex, setActiveEventIndex] = useState(0);

const handleCircleClick = (index) => {
  setActiveEventIndex(index);
};

const activeEvent = eventData[activeEventIndex];
return(
  <Section eventsFeature>
    <Container SectionHeadline className="center">
      <LargeHeadline>See What's Happening at The Church In Brielle</LargeHeadline>
    </Container>
    <Container EventFeature>
    {eventData.map((event, index) => {
      const featureImg = getImage(event.featureImage);
      return(
        <EventCard
          HomepageEventFeature
          key={event.contentful_id}
          isActive={index === activeEventIndex}
        >
          <FeatureImage homepageFeatureImage isActive={index === activeEventIndex}>
            <GatsbyImage
              image={featureImg}
              alt=""
              loading='lazy'
              className="full-width"
            />
          </FeatureImage>
          <EventCardHeadline>{event.eventHeadline}</EventCardHeadline>
          
        </EventCard>
      )
    })}
      <div className="buttonWrapper">
          {activeEvent && (
          <Button primaryButton>
            <InternalLink to={`/events/${activeEvent.slug}`}>
              See Event
            </InternalLink>
          </Button>
          )}
          <Button primaryButton><InternalLink to="/events">All Events</InternalLink></Button>
      </div>
    </Container>
    <Pagination>
      {eventData.map((event, index) => {
        return(
          <Circle
            className="circle"
            key={event.contentful_id}
            active={index === activeEventIndex}
            onClick={() => handleCircleClick(index)}
          />
        )
      })}
    </Pagination>
  </Section>
) 
}