import React from 'react';
import { BannerContainer, BannerImage, BannerTextWrapper, BannerHeadline } from '../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BannerTextRender } from './banner-text-render';

export const PageBanner = ({ bannerData }) => {
  const BannerBackground = getImage(bannerData.bannerImage) || '';
  const BannerTitle = bannerData.bannerTitle || '';
  
  return(
    <BannerContainer>
    {/* 
      This will be changed later on to accept an image render or render with out one based on input selection from contentful 
    */}
      <BannerImage>
        <GatsbyImage
          image={BannerBackground}
          alt=''
          loading='eager'
          className='full-width'
        />
      </BannerImage>
      {/* Text Content in banner */}
      <BannerTextWrapper>
        <BannerHeadline>{BannerTitle}</BannerHeadline>
        <BannerTextRender content={bannerData.subHeadline}/>
      </BannerTextWrapper>
    </BannerContainer>
  )
}