import styled from 'styled-components';

export const Pagination = styled.div`
  grid-column: 2/4;
  grid-row: 3/4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: .5em;
  padding: 1em 0em;
`
export const Circle = styled.button`
    height: 1.5em;
    width: 1.5em;
    background: ${({theme}) => theme.colors.accent};
    border-radius: 1em;
    cursor: pointer;
    opacity: ${(props) => (props.active ? '1' : '.5')};
    border: none;
    transition: ${(props) => (props.active ? 'opacity .5s ease-in-out' : 'opacity .5s ease-in-out')}
`