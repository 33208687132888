import React, { useState,useRef } from 'react';
import { NavLink, NavLinksWrapper, NavLinkWrapper, SubMenu,Arrow,Hamburger,SlideOutMenu} from '../styles';
import useOnClickOutside from "../hooks/useOnClickOutside";

 export const Mobile = () => {
    const [dropImNew, setImNew] = useState(false);
    const [dropMinistries, setMinistries] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const openImNew = () => setImNew(!dropImNew);
    const openMinistries = () => setMinistries(!dropMinistries);
    const openMenu = () => setMenuOpen(!menuOpen);
    const closeMenu = () => setMenuOpen(false);

    const closeImNew = useRef(null);
    const closeMinistries = useRef(null);

    useOnClickOutside(closeImNew, () => setImNew(false));
    useOnClickOutside(closeMinistries, () => setMinistries(false));

  return(
    <NavLinksWrapper Mobile>
    <Hamburger onClick={openMenu} menuOpen={menuOpen}>
      <div/>
      <div/>
      <div/>
    </Hamburger>
    <SlideOutMenu menuOpen={menuOpen}>
      <NavLinksWrapper MobileMenu>
      <NavLinkWrapper 
        onClick={openImNew} dropImNew={dropImNew} >
      <NavLink barlink="true" to="#">
     About
      <div>
        <Arrow/>
      </div>
      </NavLink>
      <SubMenu MobileSub dropImNew={dropImNew} className="im_new">
        <NavLinksWrapper MobileSubLinks ref={closeImNew}>
          <NavLinkWrapper SubLinkWrapper>
            <NavLink sublink="true" to="/about/our-church" onClick={closeMenu}>Our Church</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper SubLinkWrapper>
          <NavLink sublink="true" to="/about/inclusivity-message" onClick={closeMenu}>Inclusivity Message</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper SubLinkWrapper>
          <NavLink sublink="true" to="/about/our-beliefs" onClick={closeMenu}>Our Beliefs</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper SubLinkWrapper>
          <NavLink sublink="true" to="/about/preschool" onClick={closeMenu}>Preschool</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper SubLinkWrapper>
          <NavLink sublink="true" to="/about/leaders" onClick={closeMenu}> Our Leaders</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper SubLinkWrapper>
          <NavLink sublink="true" to="/about/worship-service" onClick={closeMenu}>Worship Service</NavLink>
          </NavLinkWrapper>
        </NavLinksWrapper>
      </SubMenu>
      </NavLinkWrapper>
      <NavLinkWrapper onClick={openMinistries} dropMenu={dropMinistries} ref={closeMinistries} >
        <NavLink barlink="true">
          Ministries
          <div>
            <Arrow/>
          </div>
        </NavLink>
        <SubMenu MobileSub dropMinistries={dropMinistries} className="ministries">
          <NavLinksWrapper MobileSubLinks>
            <NavLinkWrapper><NavLink sublink="true" to="/ministries/music-liturgical-arts" onClick={closeMenu} >Music & Lit Arts</NavLink></NavLinkWrapper>
            <NavLinkWrapper><NavLink sublink="true" to="/ministries/mission-outreach" onClick={closeMenu} >Missions</NavLink></NavLinkWrapper>
          </NavLinksWrapper>
        </SubMenu>
      </NavLinkWrapper>
      <NavLinkWrapper><NavLink barlink="true" to="/events" onClick={closeMenu}>Events</NavLink></NavLinkWrapper>
      <NavLinkWrapper><NavLink barlink="true" to="/give" onClick={closeMenu}>Give</NavLink></NavLinkWrapper>
      </NavLinksWrapper>
      </SlideOutMenu>
    </NavLinksWrapper>
  )
}