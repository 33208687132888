import styled,{css} from "styled-components";

export const Container = styled.div` 
  /* 
  Containers will have template literal tags that will be used to render the components. 
  These template literals will be used as a selection for the type of container being used and then options will be used as classNames.
  Note that if animations are used then the approach will differ.
  */

  ${props => props.EventCards && css`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    grid-column: 4/12;
    grid-row: 1/2;
    gap: 2em 2em;
    justify-self:center;
    align-self:center;
   
   
  
    @media ${({theme}) => theme.breakpoints.tablet}{
      grid-column: 2/10;
      grid-row: 1/2;
    }
    @media ${({theme}) => theme.breakpoints.mobile}{
      grid-column: 2/6;
      grid-row: 1/2;
    }
  `}
  ${props => props.EventFeature && css`
    grid-column: 2/4;
    grid-row: 2/3;
    display: flex;
    flex-flow: column nowrap;
    gap: 1em;

    .buttonWrapper{
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      gap: 1em;
    }
    @media ${({theme}) => theme.breakpoints.tablet}{
      grid-column: 2/4;
      grid-row: 2/3;
    }
    @media ${({theme}) => theme.breakpoints.mobile}{
      grid-column: 2/4;
      grid-row: 2/3;
    }
  `}
  ${props => props.SectionHeadline && css`
    grid-column: 2/4;
    grid-row: 1/2;
    
    &.center{
      text-align:center;
      width:100%;
    }
    @media ${({theme}) => theme.breakpoints.tablet}{
      grid-column: 2/4;
      grid-row: 1/2;
    }
    @media ${({theme}) => theme.breakpoints.mobile}{
      grid-column: 2/4;
      grid-row: 1/2;
    }
  `}

  /*Grid Positioning Inputs*/
  
`